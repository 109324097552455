<template>
  <div>
    <Banner />
    <Introduction />
    <Equipment />
    <SuccessfulCase />
    <Footer />
  </div>
</template>

<script>
import Banner from "../components/LandingPage/Banner.vue";
import Introduction from "../components/LandingPage/Introduction.vue";
import Equipment from "../components/LandingPage/Equipment.vue";
import SuccessfulCase from "../components/LandingPage/SuccessfulCase.vue";
import Footer from "../components/LandingPage/Footer.vue";

export default {
  name: "LandingPage",
  components: {
    Banner,
    Introduction,
    Equipment,
    SuccessfulCase,
    Footer,
  },
};
</script>

<style lang="scss" scoped></style>
