<template>
  <nav class="navbar navbar-expand-lg nav justify-content-between">
    <span class="navbar-brand"
      ><img src="@/assets/navbar/logo.png" alt="logo" class="logo__img d-flex"
    /></span>
    <div class="btn-group d-flex justify-content-center">
      <button
        class="dropdown-toggle nav__dashboard__text"
        type="button"
        id="navbarDropdown"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {{ title }}
      </button>
      <div class="dropdown-menu">
        <h4 class="dropdown-header">主田區</h4>
        <router-link
          :to="{
            name: 'FieldDashboard',
            params: { id: parentFieldsInfo[0].id },
          }"
          style="text-decoration: none"
          ><span class="dropdown-item">{{
            parentFieldsInfo[0].name
          }}</span></router-link
        >
        <hr class="dropdown-divider" />
        <h4 class="dropdown-header">子田區</h4>
        <router-link
          :to="{
            name: 'FieldDashboard',
            params: { id: childFieldInfo[0].id },
          }"
          style="text-decoration: none"
          ><span class="dropdown-item">{{
            childFieldInfo[0].name
          }}</span></router-link
        >
      </div>

      <span class="nav-item align-self-center ml-2">
        <button
          data-toggle="modal"
          data-target="#fieldModal"
          type="button"
          class="nav__icon d-flex h5 mb-0"
        >
          <PlusCircle tooltip="新增田區" />
        </button>
      </span>
    </div>
    <div class="dropdown person-circle__dropdown float-right">
      <button
        class="nav__icon d-flex align-items-center"
        type="button"
        id="dropdownMenuButton"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="1em"
          height="1em"
          fill="currentColor"
          class="bi bi-person-circle h4 mb-0"
          viewBox="0 0 16 16"
        >
          <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
          <path
            fill-rule="evenodd"
            d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
          />
        </svg>
      </button>

      <div
        class="dropdown-menu dropdown-menu-right mt-2"
        aria-labelledby="dropdownMenuButton"
      >
        <router-link :to="{ name: 'Dashboard' }" style="text-decoration: none"
          ><span class="dropdown-item">田區儀表板</span></router-link
        >
        <router-link
          :to="{ name: 'CustomDashboard' }"
          style="text-decoration: none"
          ><span class="dropdown-item">自訂儀表板</span></router-link
        >
        <a
          href="https://agric-dashboard.webduino.io"
          target="_blank"
          style="text-decoration: none"
        >
          <span class="dropdown-item">其他功能</span></a
        >
        <a
          href="https://md.webduino.io/WS98B4YoS4Oc-0uRxzMPyQ"
          target="_blank"
          style="text-decoration: none"
        >
          <span class="dropdown-item">使用教學</span></a
        >
        <a
          href="./downloads/kingkit-agrikit-DM.pdf"
          download="電子型錄"
          style="text-decoration: none"
        >
          <span class="dropdown-item">電子型錄</span></a
        >
        <span class="dropdown-item">登出</span>
      </div>
    </div>
  </nav>
  <FieldModal />
</template>

<script>
import PlusCircle from "@/components/Dashboard/PlusCircle.vue";
import FieldModal from "../components/FieldModal.vue";
import { reactive, ref } from "vue";

export default {
  name: "Navbar",
  components: {
    PlusCircle,
    FieldModal,
  },
  props: {
    title: {
      type: String,
    },
  },
  setup() {
    const isFieldShow = ref(false);
    const parentFieldsInfo = reactive([
      {
        id: 0,
        name: "田區",
      },
    ]);
    const childFieldInfo = reactive([
      {
        id: 1,
        name: "子田區",
      },
    ]);

    return {
      isFieldShow,
      parentFieldsInfo,
      childFieldInfo,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_mixin";

.logo__img {
  width: 70px;
}

@media (max-width: 576px) {
  .logo__img {
    width: 45px;
  }

  button.nav__dashboard__text {
    font-size: 1.2rem;
  }
}

.nav-item__pluscircle {
  color: $main-color;
  cursor: pointer;
}

.nav__dropdown-menu {
  margin: 0;
}

.navbar-brand {
  margin-right: -1rem;
}

.dropdown-item {
  color: $main-color;
  text-decoration: none;
  display: block;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: $full-color;
    font-weight: 500;
    color: $main-color;
    text-decoration: none;
  }
}

.nav {
  &__icon,
  &__dashboard__text {
    @extend %btn-three-dots;
    color: $main-color;
  }

  &__dashboard__text {
    font-size: 1.5rem;
    font-weight: 600;
  }
}

h4 {
  color: $placeholder-text-color;
}
</style>
