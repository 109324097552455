import { createRouter, createWebHashHistory } from "vue-router";
import LandingPage from "../views/LandingPage.vue";
import Dashboard from "../views/Dashboard.vue";
import Router from "../components/NestedRouter.vue";
import CustomDashboard from "../views/CustomDashboard.vue";

const routes = [
  {
    path: "/",
    name: "LandingPage",
    component: LandingPage,
  },
  {
    path: "/dashboard",
    name: "router",
    component: Router,
    children: [
      {
        // 套用到 /dashboard/fields/:id
        path: "fields/:id",
        name: "FieldDashboard",
        component: Dashboard,
      },
      {
        // 套用到 /dashboard/fields
        path: "fields",
        name: "Dashboard",
        component: Dashboard,
      },
      {
        // 套用到 /dashboard/custom
        path: "custom",
        name: "CustomDashboard",
        component: CustomDashboard,
      },
    ],
  },
];

export const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
