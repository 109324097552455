<template>
  <div
    class="modal fade"
    id="fieldModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="fieldModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-scrollable modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header justify-content-center rounded-top title">
          <h5 class="modal-title" id="fieldModalLabel">新增田區</h5>
        </div>
        <div class="modal-body">
          <form>
            <div class="container">
              <div class="row mt-1 justify-content-center">
                <div class="col-md-6">
                  <div class="form-group">
                    <span class="mr-2">耕地面積</span>
                    <button
                      type="button"
                      class="btn btn-sm btn__location btn__location--acitve"
                      @click="switchMapMode"
                    >
                      圈選或定位
                    </button>
                    <div ref="mapDom" class="map mt-3"></div>
                  </div>
                </div>
                <div class="col-md-6 align-self-end">
                  <div class="d-md-inline-flex form-group__input">
                    <div class="form-group form-group__input mr-2">
                      <label for="fieldName"
                        >田區名稱<small>(必填)</small></label
                      >
                      <input
                        v-model="name"
                        id="fieldName"
                        class="form-control"
                        type="text"
                        placeholder="請輸入"
                      />
                    </div>
                    <div class="form-group form-group__input">
                      <label for="fieldSelect"
                        >主田區<small>(必填)</small></label
                      >
                      <select
                        v-model="belongTo"
                        id="fieldSelect"
                        class="form-control"
                      >
                        <option value="none">請選擇</option>
                        <option value="fieldId">木瓜溫室</option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="area">耕地面積</label>
                    <input
                      id="area"
                      class="form-control"
                      type="text"
                      v-model="area"
                    />
                  </div>
                  <div class="d-md-inline-flex form-group__input">
                    <div class="form-group mr-2 form-group__input">
                      <label for="longitude">經度</label>
                      <input
                        id="longitude"
                        class="form-control"
                        type="text"
                        v-model="location.lng"
                        disabled="disabled"
                      />
                    </div>
                    <div class="form-group form-group__input">
                      <label for="latitude">緯度</label>
                      <input
                        id="latitude"
                        class="form-control"
                        type="text"
                        v-model="location.lat"
                        disabled="disabled"
                      />
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="exampleFormControlTextarea1">備註</label>
                    <textarea
                      class="form-control"
                      id="exampleFormControlTextarea1"
                      rows="3"
                      v-model="note"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button
            data-dismiss="modal"
            type="button"
            class="btn btn-secondary mr-2"
          >
            取消
          </button>
          <button type="button" class="btn btn__submit btn__submit--acitve">
            送出
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref, watch, toRefs } from "vue";
import useGoogleMap from "../hooks/useGoogleMap";

export default {
  name: "FieldModal",
  setup() {
    const mapDom = ref(null);
    const { switchMapMode, position, fieldAreaSize } = useGoogleMap(mapDom);
    const field = reactive({
      name: "",
      belongTo: "none",
      area: null,
      location: {
        lat: null,
        lng: null,
      },
      note: "",
    });

    // const closeModal = () => emit('close');

    watch(position, (newValue) => {
      const [lat, lng] = newValue.split(",");
      field.location.lat = parseFloat(lat);
      field.location.lng = parseFloat(lng);
    });

    watch(fieldAreaSize, (newValue) => {
      field.area = parseFloat(newValue);
    });

    return {
      mapDom,
      switchMapMode,
      ...toRefs(field),
      // closeModal,
    };
  },
};
</script>

<style lang="scss" scoped>
// 在這邊寫 css/scss
@import "@/styles/_mixin";

.modal-header {
  color: $text-color;
  padding: 0.4rem;
  border-bottom: 0 none;
}

.title {
  background-color: $main-color;
}

.map {
  border: 1px solid $placeholder-text-color;
  height: 380px;
  width: 100%;
}

.btn__location,
.btn__location--acitve,
.btn__submit,
.btn__submit--acitve {
  background-color: $main-color;
  color: $text-color;

  &:hover {
    background-color: $main-hover-color;
    color: $text-color;
  }
}

.form-group {
  margin-bottom: 0.5rem;
}

label {
  margin-bottom: 0.1rem;
}

.form-control {
  color: $placeholder-text-color;
  font-size: 0.9rem;

  &::placeholder {
    color: $placeholder-text-color;
    opacity: 1;
    font-size: 0.9rem;
  }

  &:focus {
    @extend %form-control;
  }
}

.form-group__input {
  width: 100%;
}

select {
  @extend %select;
}
</style>
