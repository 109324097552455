<template>
  <div class="card equipment__background">
    <div class="card-body title__card__body">
      <div class="d-flex flex-row-reverse justify-content-between">
        <div
          class="col col-md-auto equipment__rowside justify-content-end icon"
        >
          <Xcircle />
          <StarFill v-if="isPinned" />
          <Star v-else />
        </div>
        <div class="col-md-11">
          <div class="row">
            <div
              class="col-6 col-md-3 d-flex align-items-center offline__col-md-6"
              v-for="item in offlineItems"
              :key="item.id"
            >
              <p class="mb-0 offline__list">
                {{ item.equipmentName }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Xcircle from "@/components/Dashboard/Xcircle.vue";
import StarFill from "@/components/Dashboard/StarFill.vue";
import Star from "@/components/Dashboard/Star.vue";
import { reactive } from "vue";

export default {
  name: "Offline",
  components: {
    Star,
    StarFill,
    Xcircle,
  },

  props: {
    isPinned: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    const offlineItems = reactive([
      {
        id: 0,
        equipmentName: "設備設備設備1離線",
      },
      {
        id: 1,
        equipmentName: "設備設備設備2離線",
      },
      {
        id: 2,
        equipmentName: "設備設備設備3離線",
      },
      {
        id: 3,
        equipmentName: "設備設備設備4離線",
      },
      {
        id: 3,
        equipmentName: "設備設備設備4離線",
      },
      {
        id: 3,
        equipmentName: "設備設備設備4離線",
      },
    ]);
    return { offlineItems };
  },
};
</script>

<style lang="scss" scoped>
// 在這邊寫 css/scss
@import "@/styles/_mixin";

.equipment__rowside {
  padding-left: 0rem;
  padding-right: 0rem;
}

.offline__col-md-6 {
  padding-left: 0.2rem;
  padding-right: 0.2rem;
}

.offline__list {
  color: $placeholder-text-color;
  @extend %message-shared;
  width: 8rem;
}

.equipment__background {
  border: 0.05rem solid $main-color;
  background-color: $full-color;
}

.title {
  &__card__body {
    padding: 0.5rem;
  }
}
</style>
