import { Loader } from "@googlemaps/js-api-loader";
import { onMounted, watch, ref, onBeforeUnmount } from "vue";
import useGeolocation from "./useGeolocation";

export default function useGoogleMap(mapDom) {
  const { location } = useGeolocation();

  const map = ref(null);
  const isDrawShown = ref(false);
  const position = ref("");
  const fieldAreaSize = ref("");
  let drawingManager;
  let handleSetMarker;
  let handleDrawField;
  let handleDrawModeChange;
  let isMapLoaded = false;
  const markers = [];
  const polygons = [];

  const GOOGLE_MAPS_API_KEY = "AIzaSyDuKk4GKcG-gATjIHKamUFZcpvgEI30vaQ";
  const mapLoader = new Loader({
    apiKey: GOOGLE_MAPS_API_KEY,
    version: "weekly",
    libraries: ["drawing", "geometry"],
  });

  const createDrawingManager = () => {
    const googleMapApi = window.google.maps;
    const drawManager = new googleMapApi.drawing.DrawingManager({
      drawingControl: true, // 繪圖工具是否顯示
      drawingControlOptions: {
        position: googleMapApi.ControlPosition.TOP_RIGHT,
        drawingModes: [
          googleMapApi.drawing.OverlayType.MARKER,
          googleMapApi.drawing.OverlayType.POLYGON,
        ],
      },

      // 繪圖設定
      polygonOptions: {
        fillColor: "#ffffff",
        fillOpacity: 2,
        strokeColor: "#ffffff",
        strokeWeight: 3,
        editable: true,
        zIndex: 1,
      },
    });

    return drawManager;
  };

  const switchMapMode = () => {
    if (!isMapLoaded) return;

    // 已顯示繪圖工具
    if (isDrawShown.value) {
      drawingManager.setMap(null);
      drawingManager.setMap(map.value);
      return;
    }
    // 未顯示繪圖工具
    drawingManager = createDrawingManager();
    drawingManager.setMap(map.value);
    isDrawShown.value = true;
  };

  // 設定座標，且加入座標時座標數量超過 1 則移除原先座標，並取得座標經緯度（小數點後七位）
  const setMarker = () => {
    if (!isMapLoaded) return;

    // 已經有監聽事件的話，先移除
    if (handleSetMarker) handleSetMarker.remove();

    handleSetMarker = window.google.maps.event.addListener(
      drawingManager,
      "markercomplete",
      (marker) => {
        // 處理舊 marker
        const oldMarker = markers.shift();
        if (oldMarker) oldMarker.setMap(null);

        // 處理新 marker
        markers.push(marker);
        marker.setMap(map.value);
        position.value = marker.getPosition().toUrlValue(7);
      }
    );
  };

  // 繪製田地，繪製田地超過 1 個時移除原田地，並計算田地大小
  const drawField = () => {
    if (!isMapLoaded) return;

    // 已經有監聽事件的話，先移除
    if (handleDrawField) handleDrawField.remove();

    handleDrawField = window.google.maps.event.addListener(
      drawingManager,
      "polygoncomplete",
      (polygon) => {
        // 處理舊繪製的田地
        const oldPolygon = polygons.shift();
        if (oldPolygon) oldPolygon.setMap(null);

        // 處理新繪製的田地
        polygons.push(polygon);
        polygon.setMap(map.value);

        // 計算繪圖大小，這邊是公頃
        fieldAreaSize.value = (
          window.google.maps.geometry.spherical.computeArea(polygon.getPath()) /
          10000
        ).toFixed(5);
      }
    );
  };

  watch(isDrawShown, (newValue) => {
    if (!newValue) return;

    // 已經有監聽事件的話，先移除
    if (handleDrawModeChange) handleDrawModeChange.remove();

    handleDrawModeChange = window.google.maps.event.addListener(
      drawingManager,
      "drawingmode_changed",
      () => {
        if (drawingManager.getDrawingMode() === "marker") {
          setMarker();
          return;
        }
        if (drawingManager.getDrawingMode() === "polygon") {
          drawField();
        }
      }
    );
  });

  const createMap = () => {
    return new Promise((resolve) => {
      mapLoader
        .load()
        .then(() => {
          map.value = new window.google.maps.Map(mapDom.value, {
            center: {
              lat: location.value.lat,
              lng: location.value.lng,
            },
            zoom: 12,
            mapTypeId: "satellite", // 預設衛星檢視
            fullscreenControl: true, // 打開全螢幕控制
            fullscreenControlOptions: {
              // 設定全螢幕 icon 位置
              position: window.google.maps.ControlPosition.LEFT_BOTTOM,
            },
          });
          resolve(map.value);
        })
        .catch(() => resolve(null));
    });
  };

  onMounted(async () => {
    const map = await createMap();
    if (map) isMapLoaded = true;
  });

  onBeforeUnmount(() => {
    if (handleDrawModeChange) handleDrawModeChange.remove();
    if (handleSetMarker) handleSetMarker.remove();
    if (handleDrawField) handleDrawField.remove();
  });

  return {
    switchMapMode,
    position,
    fieldAreaSize,
  };
}
