<template>
  <div class="align-self-end">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      class="bi bi-caret-down icon__cursor"
      viewBox="0 0 16 16"
    >
      <path
        d="M3.204 5h9.592L8 10.481 3.204 5zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z"
      />
    </svg>
  </div>
</template>

<style lang="scss" scoped>
.icon__cursor {
  cursor: pointer;
  color: $main-color;
}
</style>
