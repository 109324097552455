<template>
  <section class="col-md-4">
    <div class="bg-white rounded shadow">
      <div
        class="
          h5
          mb-0
          rounded-top
          d-flex
          pt-2
          pb-2
          pr-3
          pl-3
          justify-content-between
          title
        "
      >
        <span class="title__span" style="visibility: hidden"></span>
        <span class="d-flex align-items-center"
          ><h5 class="mb-0 mr-2">即時任務</h5>
          <button
            data-toggle="modal"
            data-target="#jobModal"
            type="button"
            class="title__plus-circle d-flex h5 mb-0"
          >
            <PlusCircle tooltip="新增任務" />
          </button>
        </span>

        <ThreeDots dotColor="white" :isVisible="isInCustomDashboard" />
      </div>
      <div class="container-fluid pb-2">
        <div class="row job__rowside">
          <div
            class="col-6 col-md-6 job__col-md-6 mt-2"
            v-for="item in [1, 2, 3]"
            :key="item"
          >
            <JobItem :isPinned="isPinned" />
          </div>
        </div>
      </div>
    </div>
  </section>
  <JobModal />
</template>

<script>
import ThreeDots from "@/components/Dashboard/ThreeDots.vue";
import PlusCircle from "@/components/Dashboard/PlusCircle.vue";
import JobItem from "@/components/Dashboard/JobItem.vue";
import JobModal from "@/components/JobModal";
import { ref } from "vue";

export default {
  name: "JobBlock",
  components: {
    JobItem,
    JobModal,
    PlusCircle,
    ThreeDots,
  },

  props: {
    isPinned: {
      type: Boolean,
      default: false,
    },
    isInCustomDashboard: {
      type: Boolean,
    },
  },

  setup() {
    const isModalShow = ref(false);

    return {
      isModalShow,
    };
  },
};
</script>

<style lang="scss" scoped>
// 在這邊寫 css/scss
@import "@/styles/_mixin";

.dropdown .dropdown-item {
  color: $main-color;
  padding: 10px 14px;
  text-decoration: none;
  display: block;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: $full-color;
  }
}

.equipment__btn__three-dots {
  @extend %btn-three-dots;
  color: $main-color;
}

.span__icon {
  color: $main-color;
  cursor: pointer;
}

.job__rowside {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.job__col-md-6 {
  padding-left: 0.2rem;
  padding-right: 0.2rem;
}

.title {
  background: $main-color;
  color: $text-color;

  &__span {
    width: 1.8rem;
  }

  &__btn__three-dots,
  &__plus-circle {
    @extend %btn-three-dots;
    color: $text-color;
  }

  &__dropdown__item {
    min-width: 5rem;
  }
}
</style>
