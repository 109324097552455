<template>
  <!-- 感測器 -->
  <section class="tm-section">
    <div class="container">
      <div class="row justify-content-center pt-3 pb-3">
        <div class="img01">
          <img
            src="../../assets/photos/landingpage/micro_wheather_station.jpg"
            alt=""
            class="article-image"
          />
          <div style="padding: 0px 0px 20px 20px">
            <h4 style="color: rgb(123, 155, 72)">環境傳感器</h4>
            <table>
              <tr>
                <th class="width01">光度計範圍</th>
                <td>0 至 2500 µmol/㎡/sec or</td>
              </tr>
              <tr>
                <td></td>
                <!-- eslint-disable-next-line no-irregular-whitespace -->
                <td>0.045 lux to 188,000 lux</td>
              </tr>
              <tr>
                <th>空氣溫度</th>
                <td>-30~100℃</td>
              </tr>
              <tr>
                <th>空氣濕度</th>
                <td>0~100%</td>
              </tr>
              <tr>
                <th>輸入電壓</th>
                <td>12VDC / 1A</td>
              </tr>
            </table>
          </div>
          <p class="tm-img-p">可選配土壤水分計</p>
        </div>

        <div class="img02">
          <img
            src="../../assets/photos/landingpage/smart_control.jpg"
            alt=""
            class="article-image"
          />
          <div class="table-font" style="padding: 0px 0px 0px 20px">
            <h4 style="color: rgb(123, 155, 72)">智慧控制器</h4>
            <table>
              <tr>
                <th class="width02">繼電器控制訊號</th>
                <td>直流或交流</td>
              </tr>
              <tr>
                <th>繼電器最大負載</th>
                <td>10A 250VAC /</td>
              </tr>
              <tr>
                <td></td>
                <td>10A 30VDC</td>
              </tr>
              <tr>
                <th>輸入電壓</th>
                <td>110VAC ~ 220VAC</td>
              </tr>
            </table>
          </div>
          <p class="tm-img-p">可選配流量計</p>
        </div>
      </div>
      <div class="row justify-content-center pb-5">
        <a href="./downloads/kingkit-agrikit-DM.pdf" download="電子型錄">
          <button type="button" class="btn btn-outline-secondary">
            下載 DM
          </button></a
        >
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Equipment",
};
</script>

<style lang="scss" scoped>
.tm-section {
  background-image: url("../../assets/photos/landingpage/資產1.svg");
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 36px;
}

table tr {
  color: #373c38;
  font-weight: 200;
}

.article-image {
  width: 400px;
  padding: 20px;
}

.width01 {
  width: 130px;
}

.width02 {
  width: 170px;
}

@media (max-width: 411px) {
  div > table {
    font-size: 16px;
  }
  .width01 {
    width: 100px;
  }
  .width02 {
    width: 140px;
  }

  div > .img01 {
    height: 500px;
  }
  div > .img02 {
    height: 500px;
  }
}

@media (max-width: 375px) {
  div > table {
    font-size: 16px;
  }
  .width01 {
    width: 100px;
  }
  .width02 {
    width: 140px;
  }

  div > .img01 {
    height: 500px;
  }
  div > .img02 {
    height: 500px;
  }
}

img {
  max-width: 100%;
}

.img01:hover {
  box-shadow: 5px 5px 5px rgb(0, 0, 0, 0.3);
}

.img01 {
  background-color: white;
  width: 400px;
  height: 560px;
  margin: 20px;
  /* margin-right: 15px; */
  position: relative;
}

.img02:hover {
  box-shadow: 5px 5px 5px rgb(0, 0, 0, 0.3);
}

.img02 {
  background-color: white;
  width: 400px;
  height: 560px;
  margin: 20px;
  /* margin-left: 15px; */
  position: relative;
}

.tm-img-p {
  color: white;
  background-color: rgb(123, 155, 72);
  text-align: center;
  bottom: -20px;
  position: absolute;
  width: 100%;
}
</style>
