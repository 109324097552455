<template>
  <div class="card card-body border-0 shadow">
    <div class="row d-flex justify-content-center">
      <div class="col text-center">
        <CaretUp />
        <div class="rounded span__num">00</div>
        <CaretDown />
      </div>
      <span class="align-self-center"> &#58;</span>
      <div class="col text-center">
        <CaretUp />
        <div class="rounded span__num">00</div>
        <CaretDown />
      </div>
      <span class="align-self-center"> &#58;</span>
      <div class="col text-center">
        <CaretUp />
        <div class="rounded span__num">00</div>
        <CaretDown />
      </div>
    </div>
  </div>
</template>

<script>
import CaretDown from "@/components/CaretDown.vue";
import CaretUp from "@/components/CaretUp.vue";

export default {
  name: "TimepickerBlock",
  components: {
    CaretDown,
    CaretUp,
  },
};
</script>

<style lang="scss" scoped>
.span__num {
  margin-bottom: 0rem;
  padding: 0.3rem;
  border: 1px solid $main-color;
  color: $main-color;

  &:hover {
    background-color: $main-color;
    color: $text-color;
  }
}

span {
  color: $main-color;
}
</style>
