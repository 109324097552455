<template>
  <div class="dashboard__background">
    <Navbar title="自訂儀表板" />
    <main class="container-fluid">
      <div class="row">
        <EquipmentBlock :isPinned="true" :isInCustomDashboard="true" />
        <JobBlock :isPinned="true" :isInCustomDashboard="true" />
      </div>
    </main>
  </div>
</template>

<script>
import Navbar from "../components/Navbar.vue";
import EquipmentBlock from "../components/Dashboard/EquipmentBlock.vue";
import JobBlock from "../components/Dashboard/JobBlock.vue";

export default {
  name: "CustomDashboard",
  components: {
    Navbar,
    EquipmentBlock,
    JobBlock,
  },
};
</script>

<style lang="scss" scoped>
// 在這邊寫 css/scss
@import "@/styles/_mixin";
.dashboard__background {
  background-color: $full-color;
  width: 100%;
  min-height: 100vh;
}
</style>
