<template>
  <div class="dashboard__background">
    <Navbar title="田區儀表板" />
    <main class="container-fluid">
      <div class="row">
        <EquipmentBlock :isInCustomDashboard="false" />
        <JobBlock />
      </div>
    </main>
  </div>
</template>

<script>
import Navbar from "../components/Navbar.vue";
import EquipmentBlock from "../components/Dashboard/EquipmentBlock.vue";
import JobBlock from "../components/Dashboard/JobBlock.vue";

export default {
  name: "Dashboard",
  components: {
    Navbar,
    EquipmentBlock,
    JobBlock,
  },
};
</script>

<style lang="scss" scoped>
.dashboard__background {
  background-color: $full-color;
  width: 100%;
  min-height: 100vh;
}
</style>
