<template>
  <div id="app">
    <!-- <ConditionModal /> -->
    <router-view></router-view>
  </div>
</template>

<script>
// 1. 想看切出來的版的話，可以將 Navbar 替換成檔案名稱
// 2. 比方說：import Dashboard from "./components/Dashboard.vue";
// import CustomDashboard from '@/views/CustomDashboard.vue';
// import Dashboard from '@/views/Dashboard.vue';
// import ConditionModal from '@/components/ConditionModal.vue';

export default {
  name: "App",
  // 3. 上面換了這邊也要跟著換
  components: {
    // CustomDashboard,
    // Dashboard,
    // ConditionModal,
  },
};
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 20px;
  letter-spacing: 1pt;
}

// 解決 bootstrap modal 會自動加上 padding-right 問題，不得不使用 !important
body {
  margin: 0;
  padding: 0 !important;
  overflow: auto !important;
}

a {
  transition: all 0.3s ease;
  &:hover {
    text-decoration: none;
  }
  &:focus {
    text-decoration: none;
    outline: none;
  }
}
</style>
