<template>
  <div
    class="
      row
      rounded
      p-1
      m-0
      flex-row-reverse
      justify-content-between
      equipment__background
    "
  >
    <div class="col-md-auto equipment__rowside pt-1 pr-1">
      <Xcircle />
      <StarFill v-if="isPinned" />
      <Star v-else />
    </div>
    <div class="col-md-11">
      <div class="row data">
        <div
          class="col-6 col-md-3 equipment__col-md-3"
          v-for="item in dataItems"
          :key="item.id"
        >
          <div :class="`mt-1 mb-1 card border-0 ${item.color}`">
            <div class="card-body title__card__body flex-column">
              <div class="d-flex justify-content-between">
                <span class="equipment__field__text">田區田區田區</span>
                <span class="data__span__Ttext">{{ item.status }}</span>
              </div>
              <div class="d-flex justify-content-center">
                <span class="d-flex align-items-center mr-2 icon__img"
                  ><img :src="getImg(item.img)" alt=""
                /></span>
                <span class="data__span__Tnumber"
                  >{{ item.number }}
                  <span class="data__span__Tunit"> {{ item.unit }}</span></span
                >
              </div>
              <div id="" class="d-flex justify-content-center border">
                chart位置
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Xcircle from "@/components/Dashboard/Xcircle.vue";
import StarFill from "@/components/Dashboard/StarFill.vue";
import Star from "@/components/Dashboard/Star.vue";
import { reactive } from "vue";

export default {
  name: "EnvironmentalData",
  components: {
    Star,
    StarFill,
    Xcircle,
  },

  props: {
    isPinned: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    const dataItems = reactive([
      {
        id: 0,
        field: "田區田區田區",
        status: "溫度",
        number: "25",
        color: "t__color",
        unit: "°C",
        img: "℃_icon.svg",
      },
      {
        id: 1,
        field: "田區田區田區",
        status: "濕度",
        number: "25",
        color: "h__color",
        unit: "%",
        img: "H_icon.svg",
      },
      {
        id: 2,
        field: "田區田區田區",
        status: "光度",
        number: "25",
        color: "p__color",
        unit: "PAR",
        img: "PAR_icon.svg",
      },
      {
        id: 3,
        field: "田區田區田區",
        status: "土壤濕度",
        number: "25",
        color: "h_2__color",
        unit: "%",
        img: "H_2_icon.svg",
      },
    ]);
    const getImg = (img) => {
      return require(`@/assets/dashboard/${img}`);
    };
    return { dataItems, getImg };
  },
  // methods: {
  //   getImg(img) {
  //     return require(`@/assets/dashboard/${img}`);
  //   },
  // },
};
</script>

<style lang="scss" scoped>
// 在這邊寫 css/scss
@import "@/styles/_mixin";

.h_2__color {
  color: $chart-H-color;
}

.p__color {
  color: $next-color;
}

.h__color {
  color: $delete-color;
}

.t__color {
  color: $main-color;
}

// 環境數據

.data {
  &__span__Ttext,
  &__span__Tnumber,
  &__span__Tunit {
    font-weight: 700;
  }

  &__span__Tnumber {
    font-size: 2rem;
  }

  &__span__Tunit {
    font-size: 1rem;
  }
}

.icon__img {
  width: 2rem;
}

.equipment__field__text {
  color: $placeholder-text-color;
  font-size: 0.8rem;
  @extend %message-shared;
  width: 4.2rem;
}

@media (max-width: 576px) {
  .equipment__field__text {
    width: 2.5rem;
    font-size: 0.5rem;
  }

  .icon__img {
    width: 1.5rem;
  }
  .data {
    &__span__Tnumber {
      font-size: 1.5rem;
    }
    &__span__Ttext {
      font-size: 0.8rem;
      margin-top: -0.1rem;
    }
    &__span__Tunit {
      font-size: 0.5rem;
    }
  }
}

.equipment__rowside {
  padding-left: 0rem;
  padding-right: 0rem;
}

.equipment__col-md-3 {
  padding-left: 0.2rem;
  padding-right: 0.2rem;
}

.equipment__background {
  border: 0.05rem solid $main-color;
  background-color: $full-color;
}

.title {
  &__card__body {
    padding: 0.5rem;
  }
}
</style>
