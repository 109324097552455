<template>
  <span class="d-flex h5 mb-0 icon__tooltip">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      class="bi bi-plus-circle"
      viewBox="0 0 16 16"
    >
      <path
        d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
      />
      <path
        d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"
      />
    </svg>
    <p :style="`width:${tooltipWidth}px`" class="icon__tooltip__text">
      {{ tooltip }}
    </p>
  </span>
</template>

<script>
export default {
  name: "PlusCircle",
  props: {
    tooltip: {
      type: String,
    },
    tooltipWidth: { type: String, default: "70" },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_mixin";

.icon {
  &__tooltip {
    position: relative;
    display: inline-block;

    &:hover {
      .icon__tooltip__text {
        visibility: visible;
      }
    }
  }

  &__tooltip__text {
    @extend %icon-shared;
  }
}
</style>
