<template>
  <div
    class="modal fade"
    id="wateringModal"
    tabindex="-1"
    aria-labelledby="wateringModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header justify-content-center">
          <h5 class="modal-title" id="wateringModalLabel">條件設定</h5>
        </div>
        <div class="modal-body">
          <form>
            <div class="container">
              <!-- <div class="row justify-content-center">
                <div class="form-group col-10">
                  <label for="">馬達控制器</label>
                  <select id="inputState" class="form-control">
                    <option selected>請選擇</option>
                    <option>...</option>
                  </select>
                </div>
              </div> -->
              <div class="row mt-1 justify-content-center">
                <div class="col d-flex">
                  <div class="form-group field__input flex-row">
                    <label class="mr-2" for="wateringType">灌溉類別</label>
                    <div class="type__layout">
                      <div class="form-check-inline">
                        <input
                          v-model="wateringRadio"
                          class="form-check-input"
                          type="radio"
                          name="exampleRadios"
                          id="wateringTime"
                          value="wateringTime"
                          checked
                        />
                        <label class="form-check-label mb-0" for="wateringTime"
                          >灌溉計時器</label
                        >
                      </div>
                      <div class="form-check-inline">
                        <input
                          v-model="wateringRadio"
                          class="form-check-input"
                          type="radio"
                          name="exampleRadios"
                          id="watering"
                          value="watering"
                        />
                        <label class="form-check-label mb-0" for="watering"
                          >灌溉水量</label
                        >
                      </div>
                    </div>

                    <input
                      id="wateringType"
                      class="form-control"
                      type="text"
                      :placeholder="
                        wateringRadio === 'wateringTime' ? '00:00:00' : '0L'
                      "
                    />
                  </div>
                  <div class="form-group d-flex align-self-end">
                    <button
                      v-if="wateringRadio === 'wateringTime'"
                      class="btn__stopwatch ml-2 mb-2"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseExample"
                      aria-expanded="false"
                      aria-controls="collapseExample"
                    >
                      <Stopwatch />
                    </button>
                    <span v-else class="form-group d-flex align-self-end ml-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="1.4em"
                        height="1.4em"
                        fill="currentColor"
                        class="bi bi-droplet-half"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M7.21.8C7.69.295 8 0 8 0c.109.363.234.708.371 1.038.812 1.946 2.073 3.35 3.197 4.6C12.878 7.096 14 8.345 14 10a6 6 0 0 1-12 0C2 6.668 5.58 2.517 7.21.8zm.413 1.021A31.25 31.25 0 0 0 5.794 3.99c-.726.95-1.436 2.008-1.96 3.07C3.304 8.133 3 9.138 3 10c0 0 2.5 1.5 5 .5s5-.5 5-.5c0-1.201-.796-2.157-2.181-3.7l-.03-.032C9.75 5.11 8.5 3.72 7.623 1.82z"
                        />
                        <path
                          fill-rule="evenodd"
                          d="M4.553 7.776c.82-1.641 1.717-2.753 2.093-3.13l.708.708c-.29.29-1.128 1.311-1.907 2.87l-.894-.448z"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
              <div v-if="wateringRadio === 'wateringTime'" class="row">
                <div class="col d-flex justify-content-end">
                  <div class="border__input d-flex justify-content-between">
                    <div
                      class="collapse collapse__watering"
                      id="collapseExample"
                    >
                      <TimepickerBlock />
                    </div>
                  </div>
                </div>
              </div>

              <div class="row justify-content-center mt-2">
                <div class="col">
                  <div class="form-group">
                    <div class="d-flex">
                      <label class="mr-2" for="">間歇設定</label>
                      <div class="custom-control custom-switch">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="customSwitch1"
                        />
                        <label
                          class="custom-control-label"
                          for="customSwitch1"
                        ></label>
                      </div>
                    </div>
                  </div>
                  <div class="form-group field__input">
                    <label class="mr-2" for="deliverTime">供水計時器</label>
                    <div class="d-flex">
                      <input
                        id="deliverTime"
                        class="form-control"
                        type="text"
                        placeholder="00:00:00"
                      />
                      <button
                        class="btn__stopwatch ml-2 mb-1"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseExample2"
                        aria-expanded="false"
                        aria-controls="collapseExample"
                      >
                        <Stopwatch />
                      </button>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col d-flex justify-content-end">
                      <div class="border__input d-flex justify-content-between">
                        <div class="collapse" id="collapseExample2">
                          <TimepickerBlock />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="form-group field__input">
                    <label class="mr-2" for="stopTime">暫停計時器</label>
                    <div class="d-flex">
                      <input
                        id="stopTime"
                        class="form-control"
                        type="text"
                        placeholder="00:00:00"
                      />
                      <button
                        class="btn__stopwatch ml-2 mb-1"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseExample3"
                        aria-expanded="false"
                        aria-controls="collapseExample"
                      >
                        <Stopwatch />
                      </button>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col d-flex justify-content-end">
                      <div class="border__input d-flex justify-content-between">
                        <div class="collapse" id="collapseExample3">
                          <TimepickerBlock />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button data-dismiss="modal" type="button" class="btn btn-secondary">
            取消
          </button>
          <button
            @click="lastStep"
            type="button"
            class="btn btn__back btn__back--active"
          >
            上一步
          </button>
          <button type="button" class="btn btn__submit btn__submit--active">
            送出
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TimepickerBlock from "@/components/TimepickerBlock.vue";
import Stopwatch from "@/components/Stopwatch.vue";
import { ref } from "vue";

export default {
  name: "WateringModal",
  components: {
    Stopwatch,
    TimepickerBlock,
  },

  setup(_, { emit }) {
    const closeModal = () => emit("close");
    const lastStep = () => emit("last");
    const wateringRadio = ref("wateringTime");
    return {
      closeModal,
      lastStep,
      wateringRadio,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_mixin";

@media (min-width: 576px) {
  .type__layout {
    display: inline-flex;
  }
}

.collapse__watering {
  z-index: 2;
}

.field__input {
  width: 100%;
}

.card-body {
  padding: 0.5rem;
}

.modal-header {
  color: $text-color;
  padding: 0.4rem;
  border-bottom: 0 none;
  background-color: $main-color;
}

.btn__stopwatch {
  @extend %btn-three-dots;
  color: $main-color;
}

.span__text {
  color: $placeholder-text-color;
}

span {
  color: $main-color;
}

.btn__submit,
.btn__submit--active {
  background-color: $main-color;
  color: $text-color;

  &:hover {
    background-color: $main-hover-color;
    color: $text-color;
  }
}

.border__input {
  height: 0px;
}

.btn__back,
.btn__back--active {
  background-color: $next-color;
  color: $text-color;

  &:hover {
    color: $text-color;
    background-color: $next-hover-color;
  }
}

.form-group {
  margin-bottom: 0.5rem;
}

label {
  margin-bottom: 0.1rem;
}

.form-control {
  color: $placeholder-text-color;
  font-size: 0.9rem;

  &::placeholder {
    color: $placeholder-text-color;
    opacity: 1;
    font-size: 0.9rem;
  }

  &:focus {
    @extend %form-control;
  }
}

.collapsing {
  transition: none;
}
</style>
