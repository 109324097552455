<template>
  <div class="card equipment__background">
    <div class="card-body title__card__body text-center">
      <Xcircle />
      <StarFill v-if="isPinned" />
      <Star v-else tooltipLeft="-0.4" />
      <div class="job__name text-center">
        {{ job.title }}
      </div>
      <div class="job__time">{{ job.time }} <br />{{ job.text }}</div>

      <div class="custom-control custom-switch mt-1">
        <input
          type="checkbox"
          class="custom-control-input"
          id="customSwitch1"
        />
        <label class="custom-control-label" for="customSwitch1"></label>
      </div>
    </div>
  </div>
</template>

<script>
import Xcircle from "@/components/Dashboard/Xcircle.vue";
import StarFill from "@/components/Dashboard/StarFill.vue";
import Star from "@/components/Dashboard/Star.vue";
import { reactive } from "vue";

export default {
  name: "JobItem",
  components: {
    Star,
    StarFill,
    Xcircle,
  },

  props: {
    isPinned: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    const job = reactive({
      id: 0,
      title: "任務名稱任務名稱任務名稱",
      time: "2020/05/24 00:00:00",
      text: "正在執行",
      isOn: false,
    });
    return { job };
  },
};
</script>

<style lang="scss" scoped>
// 在這邊寫 css/scss
@import "@/styles/_mixin";

.job__name {
  color: $main-color;
  @extend %message-shared;
  width: 9rem;
}

@media (max-width: 576px) {
  .job__name {
    width: 6.5rem;
  }
}

.job__time {
  color: $placeholder-text-color;
  font-size: 0.75rem;
}

.equipment__background {
  border: 0.05rem solid $main-color;
  background-color: $full-color;
}

.title {
  &__card__body {
    padding: 0.5rem;
  }
}
</style>
