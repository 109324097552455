<template>
  <div
    :class="`dropdown d-flex align-items-center ${
      isVisible ? 'visible' : 'invisible'
    }`"
  >
    <button
      class="equipment__btn__three-dots mb-0"
      type="button"
      id="dropdownMenuButton"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <span class="d-flex justify-content-center align-items-center title__span"
        ><svg
          xmlns="http://www.w3.org/2000/svg"
          width="1em"
          height="1em"
          fill="currentColor"
          class="bi bi-three-dots"
          viewBox="0 0 16 16"
          :style="`color:${dotColor}`"
        >
          <path
            d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"
          /></svg
      ></span>
    </button>
    <div
      class="dropdown-menu dropdown-menu-right text-center"
      aria-labelledby="dropdownMenuButton"
    >
      <span class="dropdown-item">編輯</span>
      <span class="dropdown-item">置頂</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "ThreeDots",
  props: {
    dotColor: { type: String },
    isVisible: { type: Boolean, default: false },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_mixin";

.dropdown {
  &-item {
    color: $main-color;
    text-decoration: none;
    display: block;
    border-radius: 4px;
    cursor: pointer;
    min-width: 5rem;

    &:hover {
      background-color: $full-color;
      color: $main-color;
    }
  }

  &-menu {
    min-width: 5rem;
  }
}

.equipment__btn__three-dots {
  @extend %btn-three-dots;
  color: $main-color;
}
</style>
