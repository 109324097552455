<template>
  <div
    class="modal fade"
    id="jobModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="jobModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header justify-content-center title">
          <h5 class="modal-title" id="jobModalLabel">{{ title }}任務</h5>
        </div>
        <div class="modal-body">
          <form>
            <div class="container">
              <div class="row justify-content-center">
                <div class="form-group col">
                  <label for="">任務</label>
                  <input
                    class="form-control"
                    type="text"
                    placeholder="請輸入"
                  />
                </div>
              </div>
              <div class="row justify-content-center mt-1">
                <div class="form-group col">
                  <label for="">田區</label>
                  <select class="form-control">
                    <option>請選擇</option>
                    <option>...</option>
                  </select>
                </div>
              </div>
              <div class="row justify-content-center mt-1">
                <div class="col d-inline-flex">
                  <div class="form-group blockly__input mr-3">
                    <label for="blockly">程式積木</label>
                    <select id="blockly" class="form-control">
                      <option>請選擇</option>
                      <option>...</option>
                    </select>
                  </div>
                  <div class="form-group align-self-end mr-2">
                    <button type="button" class="btn__plus-circle mb-2">
                      <PlusCircle tooltip="新增程式積木" tooltipWidth="100" />
                    </button>
                  </div>
                  <div class="form-group align-self-end ml-2">
                    <button type="button" class="btn__plus-circle mb-2">
                      <BlockIcon />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn__delete btn__delete--acitve">
            刪除
          </button>
          <button
            type="button"
            data-dismiss="modal"
            class="btn btn btn-secondary"
          >
            取消
          </button>
          <button type="button" class="btn btn__submit btn__submit--acitve">
            送出
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PlusCircle from "@/components/Dashboard/PlusCircle.vue";
import BlockIcon from "@/components/Dashboard/BlockIcon.vue";
export default {
  name: "JobModal",
  components: {
    PlusCircle,
    BlockIcon,
  },
  props: {
    title: {
      type: String,
      default: "新增",
    },
  },
  // setup(_, { emit }) {
  //   const closeModal = () => emit('close');

  //   return {
  //     closeModal,
  //   };
  // },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_mixin";

.modal-header {
  color: $text-color;
  padding: 0.4rem;
  border-bottom: 0 none;
}

.btn__plus-circle {
  color: $main-color;
  @extend %btn-three-dots;
}

.btn__delete,
.btn__delete--acitve {
  background-color: $delete-color;
  color: $text-color;

  &:hover {
    color: $text-color;
    background-color: $delete-hover-color;
  }
}

.btn__submit,
.btn__submit--acitve {
  background-color: $main-color;
  color: $text-color;

  &:hover {
    background-color: $main-hover-color;
    color: $text-color;
  }
}

.title {
  background-color: $main-color;
}

.form-group {
  margin-bottom: 0.5rem;
}

label {
  margin-bottom: 0.1rem;
}

.form-control {
  color: $placeholder-text-color;
  font-size: 0.9rem;

  &::placeholder {
    color: $placeholder-text-color;
    opacity: 1;
    font-size: 0.9rem;
  }

  &:focus {
    @extend %form-control;
  }
}

.blockly__input {
  width: 100%;
}

select {
  @extend %select;
}
</style>
