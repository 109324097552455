<template>
  <div
    class="modal fade"
    id="conditionModal"
    tabindex="-1"
    aria-labelledby="conditionModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header justify-content-center">
          <h5 class="modal-title" id="conditionModalLabel">條件設定</h5>
        </div>
        <div class="modal-body">
          <form>
            <div class="container">
              <div class="row justify-content-center">
                <div class="form-group col">
                  <label for="detectData">偵測數據</label>
                  <select id="detectData" class="form-control">
                    <option selected>請選擇</option>
                    <option>溫度</option>
                    <option>濕度</option>
                    <option>光度</option>
                    <option>土壤濕度</option>
                  </select>
                </div>
              </div>
              <div class="row mt-1 justify-content-center">
                <div class="col d-md-inline-flex">
                  <div class="form-group mr-2 form-group__input">
                    <label for="">上閾值</label>
                    <input class="form-control" type="number" placeholder="" />
                  </div>
                  <div class="form-group form-group__input">
                    <label for="">下閾值</label>
                    <input class="form-control" type="number" placeholder="" />
                  </div>
                </div>
              </div>
              <div class="row mt-1 justify-content-center">
                <div class="form-group col">
                  <label for="startFactor">開啟條件</label>
                  <select id="startFactor" class="form-control">
                    <option selected>請選擇</option>
                    <option>上閾值：開啟,下閾值：關閉</option>
                    <option>下閾值：關閉,下閾值：開啟</option>
                  </select>
                </div>
              </div>
              <div class="row mt-1 justify-content-center">
                <div class="form-group col">
                  <label for="matchEquip">對應設備裝置</label>
                  <select id="matchEquip" class="form-control">
                    <option selected>請選擇</option>
                    <option>...</option>
                  </select>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button @click="closeModal" type="button" class="btn btn-secondary">
            取消
          </button>
          <button
            @click="lastStep"
            type="button"
            class="btn btn__back btn__back--acitve"
          >
            上一步
          </button>
          <button
            @click="nextStep"
            type="button"
            class="btn btn__next btn__next--active"
          >
            下一步
          </button>
          <button type="button" class="btn btn__submit btn__next--active">
            送出
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConditionModal",
  setup(_, { emit }) {
    const closeModal = () => emit("close");
    const lastStep = () => emit("last");
    const nextStep = () => emit("next");

    return {
      closeModal,
      lastStep,
      nextStep,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_mixin";

@media (max-width: 576px) {
  .modal-footer {
    flex-direction: column;
    flex-wrap: nowrap;
  }

  .btn {
    width: 100%;
  }
}

.modal-header {
  color: $text-color;
  padding: 0.4rem;
  border-bottom: 0 none;
  background-color: $main-color;
}

.btn__back,
.btn__back--acitve,
.btn__next,
.btn__next--active {
  background-color: $next-color;
  color: $text-color;

  &:hover {
    color: $text-color;
    background-color: $next-hover-color;
  }
}

.btn__submit,
.btn__submit--acitve {
  background-color: $main-color;
  color: $text-color;

  &:hover {
    background-color: $main-hover-color;
    color: $text-color;
  }
}

.form-group {
  margin-bottom: 0.5rem;
}

label {
  margin-bottom: 0.1rem;
}

.form-control {
  color: $placeholder-text-color;
  font-size: 0.9rem;

  &::placeholder {
    color: $placeholder-text-color;
    opacity: 1;
    font-size: 0.9rem;
  }

  &:focus {
    @extend %form-control;
  }
}

.form-group__input {
  width: 100%;
}

select {
  @extend %select;
}
</style>
