<template>
  <!-- 聯絡資訊 -->
  <section
    ref="footerDom"
    id="contact"
    class="tm-section-pad-top tm-parallax-2"
  >
    <div class="container tm-container-contact">
      <div class="row">
        <div class="col-12">
          <h2 class="mb-4 tm-section-title">聯絡我們</h2>
          <div class="mb-5 tm-underline">
            <div class="tm-underline-inner"></div>
          </div>
          <p class="mb-5">
            慶奇科技股份有限公司包含硬體、軟體、韌體及雲端四大方向之整合性技術，在物聯網教育長期耕耘，
            有鑒於希望物聯教育的應用能在實務上落地發展，故在 2016
            年與桃園區農業改良場、高雄區農業改良場開始長期配合，
            進行智慧農業系統的開發、生產、改良。
          </p>
        </div>

        <div
          class="
            col-sm-12 col-md-12 col-lg-4
            d-flex
            align-items-center
            tm-contact-item
          "
        >
          <a href="tel:07-3388511" class="tm-contact-item-link">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              fill="currentColor"
              class="bi bi-telephone-fill"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
              />
            </svg>
            <span style="margin-left: 28px; font-size: 18px">07-3388511</span>
          </a>
        </div>
        <div
          class="
            col-sm-12 col-md-12 col-lg-4
            d-flex
            align-items-center
            tm-contact-item
          "
        >
          <a href="mailto:service@webduino.io" class="tm-contact-item-link">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              fill="currentColor"
              class="bi bi-envelope-fill"
              viewBox="0 0 16 16"
            >
              <path
                d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555zM0 4.697v7.104l5.803-3.558L0 4.697zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757zm3.436-.586L16 11.801V4.697l-5.803 3.546z"
              />
            </svg>
            <span style="margin-left: 28px; font-size: 18px"
              >service@webduino.io</span
            >
          </a>
        </div>
        <div
          class="
            col-sm-12 col-md-12 col-lg-4
            d-flex
            align-items-center
            tm-contact-item
          "
        >
          <a
            href="https://www.facebook.com/pg/%E6%99%BA%E6%A0%BD%E8%BE%B2%E6%A5%AD-%E6%88%90%E5%B0%B1%E8%BE%B2%E6%A5%AD%E9%81%94%E4%BA%BA%E7%9A%84%E6%9C%80%E5%BE%8C%E4%B8%80%E5%93%A9%E8%B7%AF-115527170282011/posts/"
            target="_blank"
            class="tm-contact-item-link"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              fill="currentColor"
              class="bi bi-facebook"
              viewBox="0 0 16 16"
            >
              <path
                d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"
              />
            </svg>
            <span style="margin-left: 28px; font-size: 18px"
              >慶奇科技樂農智慧農業粉絲專頁</span
            >
          </a>
        </div>

        <div
          class="
            col-sm-12 col-md-12 col-lg-4
            d-flex
            align-items-center
            tm-contact-item
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            fill="currentColor"
            class="bi bi-people-fill"
            viewBox="0 0 16 16"
          >
            <path
              d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
            />
            <path
              fill-rule="evenodd"
              d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z"
            />
            <path d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z" />
          </svg>

          <span style="margin-left: 28px; font-size: 18px">合作廠商 </span>
        </div>
        <div
          class="
            col-sm-12 col-md-12 col-lg-4
            d-flex
            align-items-center
            tm-contact-item
          "
        >
          <a
            href="https://webduino.io"
            target="_blank"
            class="tm-contact-item-link"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="42"
              height="40"
              fill="currentColor"
              class="bi bi-globe2"
              viewBox="0 0 16 16"
            >
              <path
                d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855-.143.268-.276.56-.395.872.705.157 1.472.257 2.282.287V1.077zM4.249 3.539c.142-.384.304-.744.481-1.078a6.7 6.7 0 0 1 .597-.933A7.01 7.01 0 0 0 3.051 3.05c.362.184.763.349 1.198.49zM3.509 7.5c.036-1.07.188-2.087.436-3.008a9.124 9.124 0 0 1-1.565-.667A6.964 6.964 0 0 0 1.018 7.5h2.49zm1.4-2.741a12.344 12.344 0 0 0-.4 2.741H7.5V5.091c-.91-.03-1.783-.145-2.591-.332zM8.5 5.09V7.5h2.99a12.342 12.342 0 0 0-.399-2.741c-.808.187-1.681.301-2.591.332zM4.51 8.5c.035.987.176 1.914.399 2.741A13.612 13.612 0 0 1 7.5 10.91V8.5H4.51zm3.99 0v2.409c.91.03 1.783.145 2.591.332.223-.827.364-1.754.4-2.741H8.5zm-3.282 3.696c.12.312.252.604.395.872.552 1.035 1.218 1.65 1.887 1.855V11.91c-.81.03-1.577.13-2.282.287zm.11 2.276a6.696 6.696 0 0 1-.598-.933 8.853 8.853 0 0 1-.481-1.079 8.38 8.38 0 0 0-1.198.49 7.01 7.01 0 0 0 2.276 1.522zm-1.383-2.964A13.36 13.36 0 0 1 3.508 8.5h-2.49a6.963 6.963 0 0 0 1.362 3.675c.47-.258.995-.482 1.565-.667zm6.728 2.964a7.009 7.009 0 0 0 2.275-1.521 8.376 8.376 0 0 0-1.197-.49 8.853 8.853 0 0 1-.481 1.078 6.688 6.688 0 0 1-.597.933zM8.5 11.909v3.014c.67-.204 1.335-.82 1.887-1.855.143-.268.276-.56.395-.872A12.63 12.63 0 0 0 8.5 11.91zm3.555-.401c.57.185 1.095.409 1.565.667A6.963 6.963 0 0 0 14.982 8.5h-2.49a13.36 13.36 0 0 1-.437 3.008zM14.982 7.5a6.963 6.963 0 0 0-1.362-3.675c-.47.258-.995.482-1.565.667.248.92.4 1.938.437 3.008h2.49zM11.27 2.461c.177.334.339.694.482 1.078a8.368 8.368 0 0 0 1.196-.49 7.01 7.01 0 0 0-2.275-1.52c.218.283.418.597.597.932zm-.488 1.343a7.765 7.765 0 0 0-.395-.872C9.835 1.897 9.17 1.282 8.5 1.077V4.09c.81-.03 1.577-.13 2.282-.287z"
              />
            </svg>
            <span class="item" style="margin-left: 30px; font-size: 18px"
              >webduino.io</span
            >
          </a>
        </div>
      </div>
    </div>
    <footer class="text-center small tm-footer">
      <p class="mb-0">Copyright &copy; 2021 慶奇科技</p>
    </footer>
  </section>
</template>

<script>
import { footerDom } from "@/store";
export default {
  name: "Footer",
  setup() {
    return { footerDom };
  },
};
</script>

<style lang="scss" scoped>
#contact {
  color: white;
  background: url("../../assets/photos/landingpage/bg-b.jpg") center no-repeat;
  background-attachment: fixed;
  min-height: 700px;
  position: relative;
  padding: 100px 0px 50px 0px;
}

@media (min-width: 1200px) {
  .tm-container-contact {
    max-width: 1063px;
  }
}

.tm-section-title {
  font-size: 2.6rem;
  font-weight: normal;
}

.tm-underline {
  border-top: 2px solid white;
  height: 8px;
  width: 100%;
}

.tm-underline-inner {
  width: 25%;
  height: 8px;
  background-color: white;
}

.tm-contact-item {
  margin-bottom: 65px;

  a {
    text-decoration: none;
  }

  &-link {
    display: flex;
    align-items: center;

    svg,
    span {
      color: white;
      transition: all 0.3s ease;
    }

    &:hover {
      svg,
      span {
        color: rgb(123, 155, 72);
      }
    }
  }
}

.tm-footer {
  position: absolute;
  bottom: 35px;
  left: 0;
  right: 0;
  padding: 0 15px;

  &-link {
    color: white;

    &:hover,
    &:focus {
      color: rgb(92, 170, 127);
    }
  }
}
</style>
