<template>
  <!-- 成功案例 -->
  <section ref="successDom" id="work" class="tm-section-pad-top">
    <div class="container tm-container-gallery">
      <div class="row justify-content-center">
        <div class="col-lg-6">
          <h2 class="tm-text-primary tm-section-title mb-4 text-center">
            成功案例
          </h2>
          <p class="mx-auto tm-container-contact tm-p">
            使用慶奇科技的樂農智慧農業系統，不僅省水省肥，更能有效降低產量損失
            20%，大幅降低場域所需人力 40%，是您升級智慧農場的絕佳選擇。
          </p>
        </div>
      </div>
      <div class="mx-auto tm-gallery-container">
        <div v-if="!isShow" class="row">
          <div
            class="col-md-4 cover"
            data-toggle="modal"
            data-target="#modal-0"
          >
            <img
              :src="cases[0].coverImageSrc"
              class="img-fluid cover__image"
              :alt="cases[0].coverImageAlt"
            />
            <div class="overlay">點我看更多</div>
          </div>
          <div
            class="col-md-4 cover"
            data-toggle="modal"
            data-target="#modal-1"
          >
            <img
              :src="cases[1].coverImageSrc"
              class="img-fluid cover__image"
              :alt="cases[1].coverImageAlt"
            />
            <div class="overlay">點我看更多</div>
          </div>
          <div
            class="col-md-4 cover"
            data-toggle="modal"
            data-target="#modal-2"
          >
            <img
              :src="cases[2].coverImageSrc"
              class="img-fluid cover__image"
              :alt="cases[2].coverImageAlt"
            />
            <div class="overlay">點我看更多</div>
          </div>
        </div>
        <div v-if="isShow" class="row">
          <div class="col-12">
            <div
              id="carouselIndicators"
              class="carousel slide"
              data-ride="carousel"
            >
              <ol class="carousel-indicators">
                <li
                  data-target="#carouselIndicators"
                  data-slide-to="0"
                  class="active"
                ></li>
                <li data-target="#carouselIndicators" data-slide-to="1"></li>
                <li data-target="#carouselIndicators" data-slide-to="2"></li>
              </ol>
              <div class="carousel-inner">
                <div
                  class="carousel-item active cover"
                  data-toggle="modal"
                  data-target="#modal-0"
                >
                  <img
                    :src="cases[0].coverImageSrc"
                    class="img-fluid cover__image"
                    :alt="cases[0].coverImageAlt"
                  />
                  <div class="overlay">點我看更多</div>
                </div>
                <div
                  class="carousel-item cover"
                  data-toggle="modal"
                  data-target="#modal-1"
                >
                  <img
                    :src="cases[1].coverImageSrc"
                    class="img-fluid cover__image"
                    :alt="cases[1].coverImageAlt"
                  />
                  <div class="overlay">點我看更多</div>
                </div>
                <div
                  class="carousel-item cover"
                  data-toggle="modal"
                  data-target="#modal-2"
                >
                  <img
                    :src="cases[2].coverImageSrc"
                    class="img-fluid cover__image"
                    :alt="cases[2].coverImageAlt"
                  />
                  <div class="overlay">點我看更多</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 彈出視窗 -->
    <div
      v-for="modal in modals"
      :key="modal.id"
      class="modal fade"
      :id="modal.modalId"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div
        class="
          modal-dialog modal-dialog-scrollable
          d-flex
          modal__bg
          justify-content-center
          align-items-center
        "
      >
        <div class="modal-content modal__box">
          <div class="modal-header d-flex justify-content-center">
            <h5 class="modal-title modal__box__title" id="exampleModalLabel">
              {{ modal.title }}
            </h5>
          </div>
          <button
            type="button"
            class="close modal__box__close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <div class="modal-body card">
            <div
              v-for="image in modal.images"
              :key="image.id"
              class="d-flex flex-column align-items-center"
            >
              <img
                :src="image.src"
                class="card-img-top modal__pic"
                alt="Image"
              />
              <div class="card-body my-3 p-0">
                <p class="card-text img-desc">{{ image.description }}</p>
              </div>
            </div>
          </div>
          <div class="modal-footer p-1">
            <!-- 這個空 div 必須存在 -->
            <div></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import useSuccessfulCase from "@/hooks/useSuccessfulCase";
import { successDom } from "@/store";

export default {
  name: "SuccessfulCase",
  setup() {
    const { cases, modals, isShow } = useSuccessfulCase();

    return { cases, modals, isShow, successDom };
  },
};
</script>

<style lang="scss" scoped>
.tm-section-pad-top {
  padding-top: 80px;
}

.tm-text-primary {
  color: rgb(123, 155, 72);
}

@media (min-width: 1200px) {
  .container {
    max-width: 1275px;
  }

  .tm-container-gallery {
    max-width: 1290px;
  }

  .tm-container-contact {
    max-width: 1063px;
  }
}

.tm-section-title {
  font-size: 2.6rem;
  font-weight: normal;

  @media (max-width: 1200px) {
    font-size: 2.6rem;
    font-weight: normal;
  }

  @media (max-width: 480px) {
    font-size: 1.5rem;
    font-weight: normal;
  }
}

.cover {
  position: relative;
  height: 319px;

  @media (max-width: 480px) {
    height: 263px;
  }

  &:hover {
    .overlay {
      opacity: 1;
    }
  }

  &__image {
    padding: 15px;
    cursor: pointer;
    z-index: 1;
    height: inherit;
  }
}

.tm-p {
  color: #373c38;
  font-weight: 200;
}

.tm-work-description {
  max-width: 650px;
  width: 100%;
}

.tm-gallery-container {
  padding-top: 30px;
  padding-bottom: 110px;
}

.img-desc {
  font-size: 18px;
  color: #373c38;
  font-weight: 200;

  @media (max-width: 375px) {
    font-size: 15px;
  }
}

.overlay {
  position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  width: fill-available;
  transition: 0.2s ease;
  opacity: 0;
  color: white;
  font-size: 20px;
  margin: 15px;
  padding: 20px;
  z-index: 2;
  text-align: center;

  @media (max-width: 379px) {
    margin: 15px 17px;
  }

  @media (min-width: 380px) and (max-width: 479px) {
    left: 50%;
    transform: translate(-50%);
    width: 310px;
    margin: 15px 0;
  }

  @media (min-width: 480px) and (max-width: 1000px) {
    left: 50%;
    transform: translate(-50%);
    width: 385px;
    margin: 15px 0;
  }

  @media (min-width: 1001px) and (max-width: 1199px) {
    width: 260px;
  }

  @media (min-width: 1200px) {
    width: 370px;
  }
}

.modal__bg {
  height: 100%;
  max-width: 50%;

  @media (max-width: 480px) {
    max-width: unset;
  }
}

.modal__pic {
  border-radius: 0;
}

.modal__box {
  height: 150vw;
  width: 120%;

  @media (max-width: 480px) {
    width: 80%;
  }

  &__title {
    color: rgb(123, 155, 72);
    position: relative;
  }

  &__close {
    position: absolute;
    right: 20px;
    top: 20px;
  }
}

.carousel-item {
  text-align: center;
  overflow: hidden;
}

.carousel-indicators {
  bottom: -70px;
  height: 20px;

  li {
    width: 17px;
    height: 1px;
    border-radius: 50%;
    margin: 0 5px;
    background: #999;
    border-color: transparent;

    &.active {
      background: rgb(123, 155, 72);
    }
  }
}
</style>
