import { ref, onMounted, onBeforeUnmount } from "vue";

export default function () {
  const bgColor = ref(`rgba(255, 255, 255, 0)`);
  const brandFontColor = ref("white");
  const fontColor = ref("white");
  const borderStyle = ref("1px solid white");
  const navDropdownBg = ref("unset");

  // 處理滾輪事件
  const handleScroll = () => {
    const scrollTop =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop;
    const windowWidth = document.body.clientWidth;

    // 依照視窗大小設定背景及字體顏色及透明度
    // 如果滑回頂部
    if (scrollTop === 0) {
      bgColor.value = `rgba(255, 255, 255, 0)`;
      brandFontColor.value = "white";
      borderStyle.value = "1px solid white";

      if (windowWidth >= 768) {
        fontColor.value = "white";
        return;
      }

      if (windowWidth < 768) {
        fontColor.value = "#8f8f8f";
        return;
      }
    }
    // 大小螢幕，往下滑
    if (scrollTop !== 0) {
      bgColor.value = "white";
      fontColor.value = "#8f8f8f";
      brandFontColor.value = "#8f8f8f";
      borderStyle.value = "1px solid #8f8f8f";
      return;
    }
  };

  // 視窗大小改變時，處理 dropdown 背景顏色
  const handleNavDropdownBgColor = () => {
    const windowWidth = document.body.clientWidth;
    if (windowWidth >= 768) {
      navDropdownBg.value = "unset";
      fontColor.value = "white";
      return;
    }
    navDropdownBg.value = "white";
    fontColor.value = "#8f8f8f";
  };

  const handleBrandHover = (e) => {
    e.target.style.color = brandFontColor.value;
  };

  const handleLinkHover = (e) => {
    e.target.style.color = fontColor.value;
  };

  onMounted(() => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleNavDropdownBgColor);
    handleNavDropdownBgColor();
  });

  onBeforeUnmount(() => {
    window.removeEventListener("scroll", handleScroll);
    window.removeEventListener("resize", handleNavDropdownBgColor);
  });

  return {
    bgColor,
    brandFontColor,
    fontColor,
    borderStyle,
    navDropdownBg,
    handleBrandHover,
    handleLinkHover,
  };
}
