<template>
  <div
    class="modal fade"
    id="equipmentModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="equipmentModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header justify-content-center">
          <h5 class="modal-title" id="equipmentModalLabel">{{ title }}設備</h5>
        </div>
        <div class="modal-body">
          <form>
            <div class="container">
              <div class="row justify-content-center">
                <div class="form-group col">
                  <label for="name">設備名稱<small>(必填)</small></label>
                  <input
                    id="name"
                    class="form-control"
                    type="text"
                    placeholder="請輸入"
                  />
                </div>
              </div>
              <div class="row mt-1 justify-content-center">
                <div class="col d-flex">
                  <div class="form-group field__input">
                    <label for="fieldInput">田區<small>(必填)</small></label>
                    <select id="fieldInput" class="form-control">
                      <option selected>請選擇</option>
                      <option>...</option>
                    </select>
                  </div>
                  <div class="form-group d-flex align-self-end">
                    <button type="button" class="btn__plus-circle ml-2 mb-2">
                      <PlusCircle tooltip="新增田區" />
                    </button>
                  </div>
                </div>
              </div>
              <div class="row mt-1 justify-content-center">
                <div class="col d-md-inline-flex">
                  <div class="form-group mr-2 ssid__input">
                    <label for="ssid">SSID</label>
                    <input
                      id="ssid"
                      class="form-control"
                      type="text"
                      placeholder="請輸入"
                    />
                  </div>
                  <div class="form-group deviceid__input">
                    <label for="deviceId">Device ID</label>
                    <input
                      id="deviceId"
                      class="form-control"
                      type="text"
                      placeholder="請輸入"
                    />
                  </div>
                </div>
              </div>
              <div class="row mt-1 justify-content-center">
                <div class="col">
                  <div class="form-group">
                    <label for="typeSelect">傳感器類別</label>
                    <select id="typeSelect" class="form-control">
                      <option selected>請選擇</option>
                      <option>環境感測器</option>
                      <option>灌溉控制器</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row mt-1 justify-content-center">
                <div class="form-group col">
                  <label for="frequencySelect">偵測頻率</label>
                  <select id="frequencySelect" class="form-control">
                    <option selected>請選擇</option>
                    <option>30 秒</option>
                    <option>1 分鐘</option>
                    <option>5 分鐘</option>
                  </select>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button data-dismiss="modal" type="button" class="btn btn-secondary">
            取消
          </button>
          <button
            @click="nextStep"
            type="button"
            class="btn btn__next btn__next--active"
          >
            下一步
          </button>
          <button type="button" class="btn btn__submit btn__submit--acitve">
            送出
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PlusCircle from "@/components/Dashboard/PlusCircle.vue";

export default {
  name: "EqipmentModal",
  components: {
    PlusCircle,
  },
  props: {
    title: {
      type: String,
      default: "新增",
    },
  },
  setup(_, { emit }) {
    // const closeModal = () => emit('close');
    const nextStep = () => emit("next");

    return {
      // closeModal,
      nextStep,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_mixin";

.modal-header {
  color: $text-color;
  padding: 0.4rem;
  border-bottom: 0 none;
  background-color: $main-color;
}

.btn__plus-circle {
  color: $main-color;
  @extend %btn-three-dots;
}

.btn__submit,
.btn__submit--acitve {
  background-color: $main-color;
  color: $text-color;

  &:hover {
    background-color: $main-hover-color;
    color: $text-color;
  }
}

.btn__next,
.btn__next--active {
  background-color: $next-color;
  color: $text-color;

  &:hover {
    color: $text-color;
    background-color: $next-hover-color;
  }
}

.form-group {
  margin-bottom: 0.5rem;
}

label {
  margin-bottom: 0.1rem;
}

.form-control {
  color: $placeholder-text-color;
  font-size: 0.9rem;

  &::placeholder {
    color: $placeholder-text-color;
    opacity: 1;
    font-size: 0.9rem;
  }

  &:focus {
    @extend %form-control;
  }
}

.field__input,
.ssid__input,
.deviceid__input {
  width: 100%;
}

select {
  @extend %select;
}
</style>
