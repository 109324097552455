<template>
  <!-- 大 banner -->
  <section ref="bannerDom" id="hero" class="text-white">
    <!-- Nav -->
    <nav
      class="navbar navbar-expand-md tm-navbar"
      id="tmNav"
      :style="{ background: bgColor }"
    >
      <div class="container">
        <div class="tm-next">
          <span
            @click="scrollTo(bannerDom)"
            class="navbar-brand"
            :style="{ color: brandFontColor }"
            onmouseover="this.style.color='#36563c'"
            @mouseout="handleBrandHover($event)"
            >慶奇科技</span
          >
        </div>
        <!-- 漢堡 -->
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="dropdown"
          data-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
          :style="{ border: borderStyle }"
        >
          <span class="navbar-toggler-icon" :style="{ color: brandFontColor }">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              fill="currentColor"
              class="bi bi-list"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
              />
            </svg>
          </span>
        </button>
        <div
          class="collapse navbar-collapse"
          id="navbarNavAltMarkup"
          :style="{ background: navDropdownBg }"
        >
          <ul class="navbar-nav ml-auto text-center">
            <li class="nav-item">
              <span
                @click="scrollTo(introductionDom)"
                class="nav-link tm-nav-link"
                :style="{ color: fontColor }"
                onmouseover="this.style.color='#36563c'"
                @mouseout="handleLinkHover($event)"
                >產品介紹</span
              >
            </li>
            <li class="nav-item">
              <span
                @click="scrollTo(successDom)"
                class="nav-link tm-nav-link"
                :style="{ color: fontColor }"
                onmouseover="this.style.color='#36563c'"
                @mouseout="handleLinkHover($event)"
                >成功案例</span
              >
            </li>
            <li class="nav-item">
              <span
                @click="scrollTo(footerDom)"
                class="nav-link tm-nav-link"
                :style="{ color: fontColor }"
                onmouseover="this.style.color='#36563c'"
                @mouseout="handleLinkHover($event)"
                >聯絡我們</span
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link tm-nav-link"
                href="https://agric-kit.webduino.io/start"
                target="_blank"
                :style="{ color: fontColor }"
                onmouseover="this.style.color='#36563c'"
                @mouseout="handleLinkHover($event)"
                >使用教學</a
              >
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <!-- banner 圖＋文 -->
    <div class="text-center tm-hero-text-container">
      <div class="tm-hero-text-container-inner">
        <h2 class="tm-hero-title">樂農智慧農業</h2>
        <p class="tm-hero-subtitle">成就農業達人的最後一哩路</p>
      </div>
    </div>

    <div class="text-center tm-intro-next">
      <a href="https://agric-dashboard.webduino.io/login" target="_blank"
        >登入樂農系統</a
      >
    </div>
  </section>
</template>

<script>
import useScrollTop from "../../hooks/useScrollTop";
import { bannerDom, introductionDom, successDom, footerDom } from "@/store";

export default {
  name: "Banner",
  setup() {
    const {
      bgColor,
      brandFontColor,
      fontColor,
      borderStyle,
      navDropdownBg,
      handleBrandHover,
      handleLinkHover,
    } = useScrollTop();

    const scrollTo = (el) => {
      window.scrollTo(0, el.offsetTop);
    };

    return {
      bgColor,
      brandFontColor,
      fontColor,
      borderStyle,
      navDropdownBg,
      handleBrandHover,
      handleLinkHover,
      scrollTo,
      bannerDom,
      introductionDom,
      successDom,
      footerDom,
    };
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  padding: 0;

  &-brand {
    color: white;
    font-size: 2rem;
    font-weight: bold;
    display: inline-block;
    cursor: pointer;
  }

  &-toggler {
    border: 1px solid white;
    width: 60px;
    padding: 0.2rem 0.15rem 0.3rem 0.15rem;
  }
}

.tm-nav-link {
  cursor: pointer;
}

.myclass {
  background-color: unset;
}

.tm-navbar {
  position: fixed;
  width: 100%;
  z-index: 1000;
  transition: all 0.3s ease;
}

.nav-item {
  list-style: none;
  font-size: 24px;
}

@media (min-width: 768px) {
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 30px;
    padding-left: 30px;
  }
}

.container {
  @media (max-width: 576px) {
    margin: 0 15px;
  }

  @media (min-width: 1200px) {
    max-width: 1275px;
  }
}

/* Hero */
#hero {
  background: url("../../assets/photos/landingpage/big_banner.jpg") no-repeat;
  background-attachment: fixed;
  height: 100vh;
  min-height: 375px;
  position: relative;

  @media (min-height: 830px) {
    background-position: center -210px;
  }

  @media (min-height: 680px) and (max-height: 829px) {
    background-position: center -300px;
  }

  @media (min-height: 500px) and (max-height: 679px) {
    background-position: center -400px;
  }

  @media (max-height: 499px) {
    background-position: center -450px;
  }
}

.tm-hero-text-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;

  &-inner {
    margin-top: -90px;
  }
}

.tm-hero-title {
  font-size: 3.5rem;
  text-shadow: 2px 2px 2px #333;
  letter-spacing: 0.2rem;
}

.tm-hero-subtitle {
  font-size: 1.25rem;
  text-shadow: 2px 2px 2px #333;
}

.tm-intro-next {
  position: absolute;
  bottom: 100px;
  right: 0;
  left: 0;

  a {
    color: white;
    font-weight: 800;
    padding: 15px;
    border: 1px solid white;
    border-radius: 6px;
    text-decoration: none;

    &:hover {
      border: none;
      color: white;
      background-color: #36563c;
    }
  }
}

@media (max-height: 480px) {
  .tm-hero-text-container-inner {
    margin-top: -40px;
  }

  .tm-intro-next {
    bottom: 20px;
  }
}
</style>
