<template>
  <!-- 產品介紹 -->
  <section ref="introductionDom" id="introduction" class="tm-section-pad-top">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-6">
          <h2 class="tm-text-primary tm-section-title mb-4 text-center">
            農耕技術結合科技
          </h2>
          <p class="mx-auto tm-container-contact tm-p">
            樂農智慧農業是以農民為本，依據農民豐富的栽種經驗，進行分析並建立場域專屬模型，透過雲端平台進行自動化控制，隨時可透過網頁了解植栽狀況。
          </p>
        </div>
      </div>
      <div class="row mt-3 justify-content-around">
        <div class="col-lg-2 col-auto text-center p-1">
          <img
            src="../../assets/photos/landingpage/環境監測.svg"
            alt="icon"
            class="introduction__img"
          />
        </div>
        <div class="col-lg-2 col-auto text-center p-1">
          <img
            src="../../assets/photos/landingpage/數據分析.svg"
            alt="icon"
            class="introduction__img"
          />
        </div>
        <div class="col-lg-2 col-auto text-center p-1">
          <img
            src="../../assets/photos/landingpage/自動化控制.svg"
            alt="icon"
            class="introduction__img"
          />
        </div>
        <div class="col-lg-2 col-auto text-center p-1">
          <img
            src="../../assets/photos/landingpage/專屬模型.svg"
            alt="icon"
            class="introduction__img"
          />
        </div>
      </div>
      <div class="row mt-3 mb-3 justify-content-center">
        <div class="in-1">
          <div class="col d-flex">
            <img
              src="../../assets/photos/landingpage/select-01.svg"
              alt=""
              class="checkSvg"
            />
            <h5>手機直接巡田、灌溉、控制設施</h5>
          </div>
          <div class="col mt-3 d-flex">
            <img
              src="../../assets/photos/landingpage/select-01.svg"
              alt=""
              class="checkSvg"
            />
            <h5>依據自身栽種經驗，<b style="color: #cc543a">無痛升級</b></h5>
          </div>
          <div class="col mt-3 d-flex">
            <img
              src="../../assets/photos/landingpage/select-01.svg"
              alt=""
              class="checkSvg"
            />
            <h5>降低農損，提升產量</h5>
          </div>
        </div>
        <div class="in-2">
          <div class="col d-flex">
            <img
              src="../../assets/photos/landingpage/select-01.svg"
              alt=""
              class="checkSvg"
            />
            <h5>精準控量，省水省肥料省農藥</h5>
          </div>

          <div class="col mt-3 d-flex">
            <img
              src="../../assets/photos/landingpage/select-01.svg"
              alt=""
              class="checkSvg"
            />
            <h5>省工節電，擴大經營規模</h5>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { introductionDom } from "@/store";

export default {
  name: "Introduction",
  setup() {
    return {
      introductionDom,
    };
  },
};
</script>

<style lang="scss" scoped>
.introduction__img {
  width: 150px;
}
.tm-section-pad-top {
  padding-top: 80px;
}

.tm-text-primary {
  color: rgb(123, 155, 72);
}

.tm-section-title {
  font-size: 2.6rem;
  font-weight: normal;
}

.tm-p {
  color: #373c38;
  font-weight: 200;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1275px;
  }

  .tm-container-contact {
    max-width: 1063px;
  }
}

@media (max-width: 1200px) {
  .tm-section-title {
    font-size: 2.6rem;
    font-weight: normal;
  }
}

@media (max-width: 480px) {
  .tm-section-title {
    font-size: 1.5rem;
    font-weight: normal;
  }
}

.checkSvg {
  width: 40px;
}

.in-1,
.in-2 {
  margin: 30px;
}

div > h5 {
  color: rgb(123, 155, 72);
  margin: 6px 0 0 9px;
}

@media (max-width: 480px) {
  div > h5 {
    font-size: 24px;
  }

  .checkSvg {
    width: 30px;
  }

  .in-2 {
    margin: 0px 30px 30px 30px;
  }
}

@media (max-width: 375px) {
  div > h5 {
    font-size: 24px;
  }

  .checkSvg {
    width: 30px;
  }

  .in-2 {
    margin: 0px 30px 30px 30px;
  }
}

img {
  max-width: 100%;
}
</style>
