import { onBeforeUnmount, onMounted, reactive, ref } from "vue";

export default function () {
  const cases = reactive([
    {
      id: 0,
      coverImageSrc: require("@/assets/photos/landingpage/papaya_2.jpg"),
      coverImageAlt: "木瓜園",
    },
    {
      id: 1,
      coverImageSrc: require("@/assets/photos/landingpage/火龍果-2.jpg"),
      coverImageAlt: "火龍果園",
    },
    {
      id: 2,
      coverImageSrc: require("@/assets/photos/landingpage/菜1.jpg"),
      coverImageAlt: "菜園",
    },
  ]);

  const modals = reactive([
    {
      id: 0,
      modalId: "modal-0",
      title: "富春山木瓜園",
      images: [
        {
          id: 0,
          src: require("@/assets/photos/landingpage/木瓜3.jpg"),
          description:
            "自動液肥控制系統：加壓馬達、電磁閥、文氏管、三台灌溉用控制器",
        },
        {
          id: 1,
          src: require("@/assets/photos/landingpage/富山春.jpg"),
          description: "電箱與控制器",
        },
        {
          id: 2,
          src: require("@/assets/photos/landingpage/木瓜4.jpg"),
          description: "環境感測器，偵測網室內環境的溫濕度。",
        },
      ],
    },
    {
      id: 1,
      modalId: "modal-1",
      title: "儒園鮮果",
      images: [
        {
          id: 0,
          src: require("@/assets/photos/landingpage/火龍果3.jpg"),

          description: "灌溉、降溫用控制器",
        },
        {
          id: 1,
          src: require("@/assets/photos/landingpage/儒園2.jpg"),
          description: "偵測環境溫濕度用環境感測器",
        },
      ],
    },
    {
      id: 2,
      modalId: "modal-2",
      title: "桃城蒔菜",
      images: [
        {
          id: 0,
          src: require("@/assets/photos/landingpage/馬達控制器1.jpg"),
          description: "馬達控制器",
        },
        {
          id: 1,
          src: require("@/assets/photos/landingpage/馬達控制器2.jpg"),
          description: "馬達控制器",
        },
        {
          id: 2,
          src: require("@/assets/photos/landingpage/馬達控制器3.jpg"),
          description: "馬達控制器偵測流量計數據保護馬達管路",
        },
        {
          id: 3,
          src: require("@/assets/photos/landingpage/灌溉控制器.jpg"),
          description: "灌溉用控制器",
        },
        {
          id: 4,
          src: require("@/assets/photos/landingpage/溫室內感測器.jpg"),
          description: "溫室內環境感測器",
        },
        {
          id: 5,
          src: require("@/assets/photos/landingpage/室外感測器.jpg"),
          description: "溫室外累積光度環境感測器",
        },
      ],
    },
  ]);

  const isShow = ref(false);

  const handleShowSlides = () => {
    const windowWidth = document.body.clientWidth;
    isShow.value = windowWidth < 1000;
  };

  onMounted(() => {
    window.addEventListener("resize", handleShowSlides);
    handleShowSlides();
  });

  onBeforeUnmount(() => {
    window.removeEventListener("resize", handleShowSlides);
  });

  return {
    cases,
    modals,
    isShow,
  };
}
