import { onBeforeUnmount, computed } from "vue";

export default function useGeolocation() {
  const isSupported = "navigator" in window && "geolocation" in navigator;
  let watcher = null;

  const location = computed(() => {
    if (isSupported) {
      watcher = navigator.geolocation.watchPosition(
        (position) => {
          return {
            lat: parseFloat(position.coords.latitude.toFixed(7)),
            lng: parseFloat(position.coords.longitude.toFixed(7)),
          };
        },
        // 如果支援定位但不允許存取位置
        (error) => {
          if (error.code === error.PERMISSION_DENIED) {
            return {
              lat: 24.5609064,
              lng: 121.0050483,
            };
          }
        }
      );
    }

    // 如果不支援的話，採用預設定位
    return {
      lat: 24.5609064,
      lng: 121.0050483,
    };
  });

  onBeforeUnmount(() => {
    if (watcher) navigator.geolocation.clearWatch(watcher);
  });

  return {
    location,
  };
}
