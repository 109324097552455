<template>
  <div class="card equipment__background">
    <div class="card-body title__card__body text-center">
      <Xcircle />
      <StarFill v-if="isPinned" />
      <Star v-else />
      <div class="equipment__control__field__text">
        {{ control.fieldName }}
      </div>
      <div class="equipment__control__name">
        {{ control.equipmentName }}
      </div>
      <div class="equipment__control__unit">{{ control.waterFlow }}L</div>
      <div class="custom-control custom-switch mt-1">
        <input
          type="checkbox"
          class="custom-control-input"
          id="customSwitch1"
        />
        <label class="custom-control-label" for="customSwitch1"></label>
      </div>
    </div>
  </div>
</template>

<script>
import Xcircle from "@/components/Dashboard/Xcircle.vue";
import StarFill from "@/components/Dashboard/StarFill.vue";
import Star from "@/components/Dashboard/Star.vue";
import { reactive } from "vue";

export default {
  name: "ControlItem",
  components: {
    Star,
    StarFill,
    Xcircle,
  },

  props: {
    isPinned: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    const control = reactive({
      id: 0,
      fieldName: "田區田區田區田區田區田區田區",
      equipmentName: "設備設備設備設備設備",
      waterFlow: 0,
      isOn: false,
    });
    return { control };
  },
};
</script>

<style lang="scss" scoped>
// 在這邊寫 css/scss
@import "@/styles/_mixin";

.equipment__control__field__text {
  color: $placeholder-text-color;
  font-size: 0.8rem;
  @extend %message-shared;
  width: 9rem;
}

.equipment__control__name {
  @extend %message-shared;
  width: 9rem;
}

@media (max-width: 576px) {
  .equipment__control__field__text,
  .equipment__control__name {
    width: 6.5rem;
  }
}

.equipment__background {
  border: 0.05rem solid $main-color;
  background-color: $full-color;
}

.title {
  &__card__body {
    padding: 0.5rem;
  }
}

.equipment__control__unit,
.equipment__control__name {
  color: $main-color;
}
</style>
