<template>
  <section class="col-md-8">
    <div class="bg-white rounded shadow">
      <div
        class="
          h5
          rounded-top
          d-flex
          pt-2
          pb-2
          pr-3
          pl-3
          justify-content-between
          title
        "
      >
        <span class="title__span" style="visibility: hidden"></span>
        <span class="d-flex align-items-center"
          ><h5 class="mb-0 mr-2">設備裝置</h5>
          <button
            data-toggle="modal"
            data-target="#equipmentModal"
            type="button"
            class="title__plus-circle d-flex h5 mb-0"
          >
            <PlusCircle tooltip="新增設備" />
          </button>
        </span>
        <ThreeDots dotColor="white" :isVisible="isInCustomDashboard" />
      </div>

      <div class="container-fluid mt-2">
        <div class="d-flex align-items-center">
          <h6 class="equipment__h6__text mb-0 mr-1">環境數據</h6>
          <ThreeDots v-if="isInCustomDashboard" :isVisible="true" />
        </div>
        <div class="mt-2" v-for="item in [1, 2]" :key="item">
          <EnvironmentalData :isPinned="isPinned" />
        </div>
        <div class="row mt-4">
          <div class="col">
            <div class="d-flex align-items-center">
              <h6 class="equipment__h6__text mb-0 mr-1">離線設備</h6>
              <ThreeDots v-if="isInCustomDashboard" :isVisible="true" />
            </div>
            <div class="row equipment__rowside">
              <div
                class="col-12 equipment__col-md-3 mt-2"
                v-for="item in [1, 2]"
                :key="item"
              >
                <Offline :isPinned="isPinned" />
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col mb-2">
            <div class="d-flex align-items-center">
              <h6 class="equipment__h6__text mb-0 mr-1">遠端控制</h6>
              <ThreeDots v-if="isInCustomDashboard" :isVisible="true" />
            </div>

            <div class="row equipment__rowside">
              <div
                class="col-6 col-md-3 equipment__col-md-3 mt-2"
                v-for="item in [1, 2, 3, 4]"
                :key="item"
              >
                <ControlItem :isPinned="isPinned" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <EquipmentModal
    v-if="isEquipmentShow"
    @close="isEquipmentShow = false"
    @next="handleToSecondStep"
  />
  <ConditionModal
    v-if="isConditionShow"
    @close="isConditionShow = false"
    @last="handleBackToFirstStep"
    @next="handleToThirdStep"
  />
  <WateringModal
    v-if="isWateringSettingShow"
    @close="isWateringSettingShow = false"
    @last="handleBackToSecondStep"
  />
</template>

<script>
import ThreeDots from "@/components/Dashboard/ThreeDots.vue";
import PlusCircle from "@/components/Dashboard/PlusCircle.vue";
import EnvironmentalData from "@/components/Dashboard/EnvironmentalData.vue";
import Offline from "@/components/Dashboard/Offline.vue";
import ControlItem from "@/components/Dashboard/ControlItem.vue";
import EquipmentModal from "@/components/EquipmentModal";
import ConditionModal from "@/components/ConditionModal";
import WateringModal from "@/components/WateringModal";
import { ref } from "vue";

export default {
  name: "EquipmentBlock",
  components: {
    EnvironmentalData,
    Offline,
    ControlItem,
    EquipmentModal,
    ConditionModal,
    WateringModal,
    PlusCircle,
    ThreeDots,
  },

  props: {
    isPinned: {
      type: Boolean,
      default: false,
    },

    isInCustomDashboard: {
      type: Boolean,
    },
  },

  setup(props) {
    const isShow = ref(false);
    const isEquipmentShow = ref(false);
    const isConditionShow = ref(false);
    const isWateringSettingShow = ref(false);

    const handleToSecondStep = () => {
      isEquipmentShow.value = false;
      isConditionShow.value = true;
    };

    const handleBackToFirstStep = () => {
      isConditionShow.value = false;
      isEquipmentShow.value = true;
    };

    const handleToThirdStep = () => {
      isConditionShow.value = false;
      isWateringSettingShow.value = true;
    };

    const handleBackToSecondStep = () => {
      isWateringSettingShow.value = false;
      isConditionShow.value = true;
    };

    isShow.value = props.isPinned && !props.isInCustomDashboard;

    return {
      isEquipmentShow,
      isConditionShow,
      isWateringSettingShow,
      handleToSecondStep,
      handleBackToFirstStep,
      handleToThirdStep,
      handleBackToSecondStep,
      isShow,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_mixin";

.stroke {
  stroke: $text-color;
}

.span__icon {
  color: $main-color;
}

.equipment__rowside {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.equipment__col-md-3 {
  padding-left: 0.2rem;
  padding-right: 0.2rem;
}

.title {
  background-color: $main-color;
  color: $text-color;

  &__span {
    width: 1.8rem;
    color: $text-color;
  }

  &__plus-circle {
    @extend %btn-three-dots;
    color: $text-color;
  }

  &__dropdown__item {
    min-width: 5rem;
  }
}

.equipment__h6__text {
  color: $main-color;
}
</style>
