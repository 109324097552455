<template>
  <span class="mb-0 d-flex icon icon__tooltip">
    <img
      src="@/assets/createjob/block_icon.svg"
      alt="blockly icon svg"
      class="btn__blockly"
    />
    <p class="icon__tooltip__text">編輯程式積木</p>
  </span>
</template>

<style lang="scss" scoped>
@import "@/styles/_mixin";

.icon {
  &__tooltip {
    position: relative;
    display: inline-block;

    &:hover {
      .icon__tooltip__text {
        visibility: visible;
      }
    }
  }

  &__tooltip__text {
    width: 100px;
    line-height: 1.2;
    @extend %icon-shared;
  }
}

.btn__blockly {
  width: 1.3rem;
}
</style>
